<template>
    <div class="apply-success">
        <div class="success-icon">
            <van-icon name="checked" color="#47C800" size="90"/>
        </div>
        <div class="success-text">恭喜您，报名成功！请等待招募专员的回访</div>
        <div class="success-btn" @click="okClick()">好的</div>

    </div>
</template>
<script>
import { Icon } from 'vant';
import {toPath} from '@/utils/common';
export default {
    components:{
        [Icon.name]: Icon
    },
    data() {
        return{}
    },
    methods: {
        okClick() {
            // const { projectId } = this.$route.query
            toPath(this, {path: '/', query: {}}, '', 'replace')
        }
    }
}
</script>

<style lang="less" scoped>
.apply-success {
    padding: 58px 15px;
    text-align: center;
    // .success-icon {
    //     text-align: center;
    // }
    .success-text {
        padding: 50px 0 78px;
        font-size:16px;
        color:rgba(49,49,52,1);
        line-height: 1.5;
        // text-align: center;
    }
    .success-btn {
        font-size:19px;
        font-weight:500;
        color: #0060C2;
        // text-align: center;
    }
}
</style>
